/* eslint-disable */

import React from 'react';
import classnames from 'classnames';
import PureInput from 'components/utilcomponents/input/PureInput';
import Select from '../Select';
import { prefixCls } from './index.scss';

export const formatMultipleValue = (value, key) => {
  const all = new Set();
  value.forEach(item => (typeof item === 'string' ? all.add(item) : item[key].split(',').forEach(str => all.add(str))));
  return [...all].join(',');
};

export default class DataList extends Select {
  static defaultProps = {
    compare: 'key',
    filter: 'name',
    header: ['name'],
    hiddenFlag: false,
  };

  static formatMultipleValue = formatMultipleValue;

  listenDel = false;
  listenCtrlA = false;
  listenCtrlD = false;

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.compare !== nextProps.compare) {
      this.setCompareFn(nextProps);
    }
    if (this.props.map !== nextProps.map) {
      this.setMapFn(nextProps);
    }
    if (this.props.format !== nextProps.format) {
      this.setFormatFn(nextProps);
    }
    if (this.props.filter !== nextProps.filter) {
      this.setFilterFn(nextProps);
    }
    if (this.props.value !== nextProps.value && this.state.active) {
      if (this.input.dataset.userInput !== nextProps.value) {
        this.filter(nextProps.value);
      }
      if (nextProps.multiple) {
        this.setState({ value: nextProps.value });
      }
    }
  }

  setFormatFn(props) {
    const format = props.format;
    if (typeof format === 'string') {
      this.format = value => formatMultipleValue(value, format);
    } else if (typeof format === 'function') {
      this.format = format;
    } else {
      this.format = () => '';
    }
  }

  renderToggle() {
    const {
      value = '',
      map,
      format,
      compare,
      multiple,
      className,
      filter,
      selectHiddenFlag,
      onSelect,
      onChange,
      onBlur,
      data,
      header,
      autoActiveFirstOption,
      hiddenFlag,
      selectFlag,
      menuClassName,
      highlight,
      onClose,
      optionRemovable,
      onRemoveOption,
      defaultValue,
      ...rest
    } = this.props;
    const { active } = this.state;
    const display = (this.display =
      selectHiddenFlag || selectFlag ? (multiple && active ? this.format(this.reflectValue) : value) : defaultValue);
    return (
      <PureInput
        {...rest}
        ref={this.refInput}
        data-field-type="DataList"
        value={defaultValue ? defaultValue : value}
        className={classnames({ [prefixCls]: true, active, multiple, [className]: className })}
        title={display}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onChange={this.onInput}
        onKeyDown={this.onKeyDown}
      />
    );
  }

  onInput = e => {
    this.onChange(e.target.value);
    this.filter(e.target.value);
    this.input && delete this.input.dataset.isSelect;
    this.input && (this.input.dataset.userInput = e.target.value);
    this.setState({ keyword: e.target.value, selectFlag: true });
    this.needAsyncSelect = false;
    this.state.cursorIndex === -1 && this.props.autoActiveFirstOption && this.setCursor(0);
  };

  reflect = (value, data) =>
    (value.split ? value.split(',') : value).map(v1 => data.find(v2 => this.compare(v1, v2)) || v1);

  isSelected = (item, value) => (this.props.multiple ? value.some(v => this.compare(v, item)) : false);

  onSelect = (v2, e) => {
    const reflectValue = this.reflectValue;
    const multiple = this.props.multiple;
    this.input && (this.input.dataset.isSelect = '1');
    this.input && (this.input.dataset.isEdited = '1');
    if (multiple) {
      const index = reflectValue.findIndex(v1 => this.compare(v1, v2));
      const newValue = [...reflectValue];
      index !== -1 ? newValue.splice(index, 1) : newValue.push(v2);
      this.setState({ value: newValue });
      e && e.preventDefault();
    } else {
      this.close();
      if (this.props.onSelect) {
        this.props.onSelect(v2);
      } else if (this.props.onChange) {
        this.onChange([v2].map(this.map)[0]);
      }

      this.needAsyncSelect = false;
    }
  };

  onClose = () => {
    if (this.input.dataset.isSelect && this.props.multiple) {
      if (this.props.onSelect) {
        this.props.onSelect(this.state.value);
      } else if (this.props.onChange) {
        this.onChange((this.state.value || []).map(this.map).join(','));
      }
    }
    this.props.onClose && this.props.onClose();
    this.setState({ active: false, onInputStatus: false });
  };
}
